<template>
  <div class="topbar-personal-container">
    <div class="topbar-personal" @click="show = true">
      <div class="avatar">
        <img :src="circleUrl" alt="" />
      </div>
      {{ userName }}
      <div v-if="show" class="personal-menu" @click="dialogVisible = true">
        <img src="@/assets/svg/exitLogin.svg" alt="" />
        退出登录
      </div>
    </div>

    <el-dialog
      :visible.sync="dialogVisible"
      width="500px"
      :show-close="false"
      class="exit_dialog"
      :top="'40vh'"
    >
      <span slot="title" :style="{ fontSize: '18px', color: '#1C2028' }">
        <i class="el-icon-warning" :style="{ fontSize: '18px', color: '#FA9600' }" />
        退出登录
      </span>
      <span :style="{ color: '#1C2028' }">请再次确认是否退出系统？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" class="dialog-footer-cancelBtn">取 消</el-button>
        <el-button type="primary" @click="exitCheck">确 定</el-button>
        <!-- <div class="cancelBtn" @click="dialogVisible = false">
          取 消
        </div>
        <div class="comfirmBtn" @click="exitCheck">
          确 定
        </div> -->
      </span>
    </el-dialog>
  </div>
</template>
<script>
import login from '@/libs/login';
// import store from '@/store';
// import { GetMdmUserInfo } from '@/api/user';

export default {
  name: 'PersonalMenu',
  data() {
    return {
      show: false,
      // username: '',
      circleUrl:
        'https://baike-med-1256891581.file.myqcloud.com/drugs_admin/91111202/bf667bfd-28e8-4160-8424-c46ed8c37c56.png',
      dialogVisible: false,
    };
  },
  computed: {
    // 用户名
    userName() {
      const usr = JSON.parse(localStorage.getItem('userName'));
      if (!this.$store.state.user.userName && usr) {
        this.$store.commit('user/updateUserName', usr);
      } else {
        return this.$store.state.user.userName;
      }
      return usr;
    },
  },
  watch: {
    $route(to, from) {
      const { path } = from;
      // 路由从登陆页跳转回系统内时重置信息
      if (path === '/login') {
        this.init();
      }
    },
  },
  mounted() {
    this.init();
    window.addEventListener('click', this.checkClickSelf);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.checkClickSelf);
  },
  methods: {
    init() {
      // 获取用户名
      // this.username = '';
      // GetMdmUserInfo().then(res => {
      //   this.username = res.user_name;
      // });
    },
    // 点击菜单以外的场合关闭菜单
    checkClickSelf(e) {
      if (!this.$el.contains(e.target)) {
        this.show = false;
      }
    },
    // 退出登陆
    exitCheck() {
      this.dialogVisible = false;
      login.clearLogin();
      this.$router.push('/login');
    },
  },
};
</script>
<style lang="scss" scoped>
@import './personal-menu.scss';
</style>
