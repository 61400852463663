<template>
  <div class="topbar">
    <!-- <div class="topbar-icon">
      <img src="./img/logo.png" alt="" />
      医疗数据管理
    </div> -->
    <el-select
      class="topbar-search-type"
      clearable
      filterable
      v-model="searchType"
      placeholder="请选择"
    >
      <el-option
        v-for="item in searchTypeList"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      ></el-option>
    </el-select>
    <el-input
      :class="'topbar-search-input'"
      placeholder="请输入关键词进行搜索"
      v-model="searchText"
      @keyup.enter.native="toSearch"
    >
      <i slot="suffix" class="el-input__icon el-icon-search" @click="toSearch"></i>
    </el-input>
    <!-- 高级搜索 -->
    <div
      class="toptab-advance-search"
      :class="{ 'advanceSeaBtn-click': this.$route.name === 'advance-search' }"
      @click="toAdvanceSearch"
    >
      高级搜索
    </div>
    <div class="topbar-space"></div>
    <!-- 消息中心 -->
    <div class="topbar-message" @click="handleMessage">
      <i class="el-icon-bell"></i>
      <div class="topbar-message-number">消息</div>
      <div class="number">
        <el-badge :value="messageTotal" :max="99" class="item"></el-badge>
      </div>
    </div>
    <personal-menu class="topbar-personal" />
  </div>
</template>
<script>
import personalMenu from '@/components/personal-menu';
import { SEARCH_TYPE, SPECIAL_CHAR } from '@/utils/constant';
import {
  GetTaskNotices,
  ReadTaskNotices,
  GetDoctorDataMatchTaskInfo,
  PopTaskNotices,
} from '@/api/message.js';
import { getBlob, downloadFile } from '@/utils/downloadExcel';
import papaparse from 'papaparse';
import info from './img/info.svg';
import { IsVip } from '@/api/dcr';
import { dateFormat } from '@/utils/util';
import login from '@/libs/login';
import { GetObjURL } from '@/api/upload';
export default {
  name: 'Topbar',
  components: {
    personalMenu,
  },
  data() {
    return {
      uin: '',
      // 当前搜索类型
      searchType: 'all',
      // 当前搜索关键字
      searchText: '',
      // 可选的搜索类型列表
      searchTypeList: SEARCH_TYPE,

      // notifyInstance: null,
      messageTotal: 0,
      messageList: [],
    };
  },
  computed: {
    role() {
      const newRole = JSON.parse(localStorage.getItem('role'));
      // 页面刷新时
      if (newRole) {
        this.$store.commit('user/updateRole', newRole);
      } else {
        return this.$store.state.user.role;
      }
      return newRole;
    },
    // 判断管理端还是客户端
    systemEnv() {
      // systemEnv为1表示管理端，2表示客户端
      return localStorage.getItem('systemEnv');
    },
  },
  watch: {
    $route() {
      this.init();
    },
  },
  mounted() {
    this.uin = login.getToken().uin;
    if (this.systemEnv === '2' && this.role === 1) {
      IsVip({ service_name: 'dcr' })
        .then(res => {
          this.isV = res.is_vip;
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    }
    this.init();
    this.getMessage();
    this.getUnreadMessage();
    setInterval(() => {
      this.getMessage();
      this.getUnreadMessage();
    }, 15000);
  },
  methods: {
    // 下载结果
    handleDownload(row) {
      console.log(row);
      GetObjURL({ url: row.file_url })
        .then(res => {
          if (row.file_name.endsWith('.csv')) {
            // 下载csv文件
            papaparse.parse(res.data.url, {
              download: true,
              complete(results, file) {
                console.log('Parsing complete:', results, file);
                if (results?.data) {
                  const csv = papaparse.unparse(results.data);
                  // console.log('downLoad', csv);
                  const content = new Blob([`\ufeff${csv}`], { type: 'text/plain;charset=utf-8' });
                  const urlObject = window.URL || window.webkitURL || window;
                  const url = urlObject.createObjectURL(content);
                  const el = document.createElement('a');
                  el.href = url;
                  el.download = row.file_name;
                  el.click();
                  urlObject.revokeObjectURL(url);
                }
              },
            });
          } else {
            // 下载excel文件
            getBlob(res.data.url, function(blob) {
              downloadFile(blob, row.file_name);
            });
          }
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    },
    // 获取未读消息
    getMessage() {
      const req = {
        task_type: '',
        task_status: 0,
        notice_status: 2,
        time_interval: [],
        has_poped: 2,
        // index: '',
        // page_size: '',
      };
      GetTaskNotices(req)
        .then(res => {
          // console.log('消息列表', res);
          this.messageList = res.notices.map(item => {
            return { ...item, autoClose: true, create_time: item.create_time.replace(/-/g, '/') };
          });
          // console.log(this.messageList);
          // this.messageTotal = res.total;

          if (this.messageList.length) {
            this.messageList.forEach(item => {
              if (item.task_type === '数据导入' && item.task_status === 3) {
                setTimeout(() => {
                  const inportMessage = this.$notify({
                    duration: 3000,
                    showClose: false,
                    dangerouslyUseHTMLString: true,
                    message: `<div style="display:flex;align-items: center;">
                        <img src=${info} alt=""><div style="font-size:16px;line-height:24px;font-weight:500;color:#1A2734">
                    【${item.task_type}】 任务状态通知：${
                      item.task_status === 3 ? '已完成' : '执行失败'
                    }
                    </div>
                    </div>
                    <div style="display:flex;justify-content: space-between;">
                      <div style="margin-top:16px;color:#0069FA;font-size:14px;line-height:22px;cursor: pointer;">
                        <span class="import">下载导入结果</span>
                        <span class="checkImport" style="margin-left:8px">查看导入数据</span>
                      </div>
                      <div style="margin-top:16px;color:#0069FA;font-size:14px;line-height:22px;cursor: pointer;">
                        <span class="read">标记为已读</span>
                      </div>
                    </div>
                    `,
                    onClose: () => {
                      if (!item.autoClose) {
                        return;
                      }
                      this.handleWindow(item.notice_id);
                    },
                  });
                  inportMessage.$el.querySelector('.import').onclick = () => {
                    this.handleDownload(item);
                  };
                  inportMessage.$el.querySelector('.checkImport').onclick = () => {
                    // const href = this.$router.resolve({
                    //   path: '/dashboard/import-export',
                    //   query: {
                    //     id: item.task_id,
                    //     type: item.data_type === 't_hco' ? '机构' : '医疗专业人士',
                    //   },
                    // });
                    // window.open(href.href, '_blank');
                    if (item.data_type === 'jurisdiction_data') {
                      const href = this.$router.resolve({
                        path: '/dashboard/import-export',
                        query: {
                          type: '辖区',
                        },
                      });
                      window.open(href.href, '_blank');
                    } else {
                      const href = this.$router.resolve({
                        path: '/dashboard/import-export',
                        query: {
                          id: item.task_id,
                          type: item.data_type === 't_hco' ? '机构' : '医疗专业人士',
                        },
                      });
                      window.open(href.href, '_blank');
                    }
                  };
                  inportMessage.$el.querySelector('.read').onclick = () => {
                    item.autoClose = false;
                    this.handleRead(item.notice_id, item.task_type);
                    inportMessage.close();
                  };
                }, 0);
              } else if (item.task_type === '数据导出' && item.task_status === 3) {
                setTimeout(() => {
                  const exportMessage = this.$notify({
                    duration: 3000,
                    showClose: false,
                    dangerouslyUseHTMLString: true,
                    message: `<div style="display:flex;align-items: center;">
                        <img src=${info} alt=""><div style="font-size:16px;line-height:24px;font-weight:500;color:#1A2734">
                    【${item.task_type}】 任务状态通知：${
                      item.task_status === 3 ? '已完成' : '执行失败'
                    }
                    </div>
                    </div>
                    <div style="display:flex;justify-content:space-between;">
                      <div style="margin-top:16px;color:#0069FA;font-size:14px;line-height:22px;cursor: pointer;">
                        <span class="imports">下载导出结果</span>
                      </div>
                      <div style="margin-top:16px;color:#0069FA;font-size:14px;line-height:22px;cursor: pointer;">
                        <span class="read">标记为已读</span>
                      </div>
                    </div>
                    `,
                    onClose: () => {
                      if (!item.autoClose) {
                        return;
                      }
                      this.handleWindow(item.notice_id);
                    },
                  });
                  exportMessage.$el.querySelector('.imports').onclick = () => {
                    this.handleDownload(item);
                  };
                  exportMessage.$el.querySelector('.read').onclick = () => {
                    item.autoClose = false;
                    this.handleRead(item.notice_id, item.task_type);
                    exportMessage.close();
                  };
                }, 0);
              } else if (item.task_type === '医生数据匹配' && item.task_status === 3) {
                setTimeout(() => {
                  const matchMessage = this.$notify({
                    duration: 3000,
                    showClose: false,
                    dangerouslyUseHTMLString: true,
                    message: `<div style="display:flex;align-items: center;">
                    <img src=${info} alt=""><div style="font-size:16px;line-height:24px;font-weight:500;color:#1A2734">
                【${item.task_type}】 任务状态通知：${
                      item.task_status === 3 ? '已完成' : '执行失败'
                    }
                </div>
                    </div>
                <div style="display:flex;justify-content: space-between;">
                  <div style="margin-top:16px;color:#0069FA;font-size:14px;line-height:22px;cursor: pointer;">
                    <span class="match">查看匹配结果</span>
                  </div>
                  <div style="margin-top:16px;color:#0069FA;font-size:14px;line-height:22px;cursor: pointer;">
                    <span class="read">标记为已读</span>
                  </div>
                </div>
                `,
                    onClose: () => {
                      if (!item.autoClose) {
                        return;
                      }
                      this.handleWindow(item.notice_id);
                    },
                  });
                  matchMessage.$el.querySelector('.match').onclick = () => {
                    this.handleMatch(item.task_id);
                  };
                  matchMessage.$el.querySelector('.read').onclick = () => {
                    item.autoClose = false;
                    this.handleRead(item.notice_id, item.task_type);
                    matchMessage.close();
                  };
                }, 0);
              } else if (item.task_type === '标签计算' && item.task_status === 3) {
                setTimeout(() => {
                  const matchMessage = this.$notify({
                    duration: 3000,
                    showClose: false,
                    dangerouslyUseHTMLString: true,
                    message: `<div style="display:flex;align-items: center;">
                    <img src=${info} alt=""><div style="font-size:16px;line-height:24px;font-weight:500;color:#1A2734">
                    标签计算任务状态通知：${item.task_status === 3 ? '已完成' : '执行失败'}
                </div>
                    </div>
                <div style="display:flex;justify-content: space-between;">
                  <div style="margin-top:16px;color:#0069FA;font-size:14px;line-height:22px;cursor: pointer;">
                    <span class="tagMatch">查看计算结果</span>
                  </div>
                  <div style="margin-top:16px;color:#0069FA;font-size:14px;line-height:22px;cursor: pointer;">
                    <span class="read">标记为已读</span>
                  </div>
                </div>
                `,
                    onClose: () => {
                      if (!item.autoClose) {
                        return;
                      }
                      this.handleWindow(item.notice_id);
                    },
                  });
                  matchMessage.$el.querySelector('.tagMatch').onclick = () => {
                    this.handleGoDetail(item.tag_id);
                  };
                  matchMessage.$el.querySelector('.read').onclick = () => {
                    item.autoClose = false;
                    this.handleRead(item.notice_id, item.task_type);
                    matchMessage.close();
                  };
                }, 0);
              } else if (item.task_type === 'DCR') {
                if (item.create_by !== 'admin' && item.dcr_operation === 1) {
                  setTimeout(() => {
                    const matchMessage = this.$notify({
                      duration: 3000,
                      showClose: false,
                      dangerouslyUseHTMLString: true,
                      message: `<div style="display:flex;">
                    <img src=${info} alt="" style="height: 18px;width: 18px;margin-top: 3px;margin-right: 8px;">
                    <div style="font-size:14px;line-height:24px;font-weight:500;color:#1A2734">
                    您已于${this.formatTime(
                      item.create_time,
                    )}成功创建DCR <span style="color:#0069FA;font-size:14px;line-height:22px;cursor: pointer;" class="btn">（${
                        item.dcr_code
                      }）</span>,正在等待管理员处理。
                    </div>
                  </div>
                <div style="display:flex;justify-content: space-between;">
                  <div style="margin-top:16px;color:#0069FA;font-size:14px;line-height:22px;cursor: pointer;">
                    <span class="dcrRead">标记为已读</span>
                  </div>
                </div>
                `,
                      onClose: () => {
                        if (!item.autoClose) {
                          return;
                        }
                        this.handleWindow(item.notice_id);
                      },
                    });
                    matchMessage.$el.querySelector('.btn').onclick = () => {
                      this.handleGoDcr(item.dcr_id);
                    };
                    matchMessage.$el.querySelector('.dcrRead').onclick = () => {
                      item.autoClose = false;
                      this.handleRead(item.notice_id, item.task_type);
                      matchMessage.close();
                    };
                  }, 0);
                }
                if (item.create_by === 'admin' && this.role === 1 && item.dcr_operation === 1) {
                  setTimeout(() => {
                    const matchMessage = this.$notify({
                      duration: 3000,
                      showClose: false,
                      dangerouslyUseHTMLString: true,
                      message: `<div style="display:flex;">
                    <img src=${info} alt="" style="height: 18px;width: 18px;margin-top: 3px;margin-right: 8px;"><div style="font-size:14px;line-height:24px;font-weight:500;color:#1A2734">
                    ${item.dcr_create_user}已于${this.formatTime(
                        item.create_time,
                      )}成功创建DCR <span style="color:#0069FA;font-size:14px;line-height:22px;cursor: pointer;" class="btn2">（${
                        item.dcr_code
                      }）</span>,等待您处理。
                </div>
                    </div>
                <div style="display:flex;justify-content: space-between;">
                  <div style="margin-top:16px;color:#0069FA;font-size:14px;line-height:22px;cursor: pointer;">
                    <span class="dcrRead2">标记为已读</span>
                  </div>
                </div>
                `,
                      onClose: () => {
                        if (!item.autoClose) {
                          return;
                        }
                        this.handleWindow(item.notice_id);
                      },
                    });
                    matchMessage.$el.querySelector('.btn2').onclick = () => {
                      this.handleGoDcr(item.dcr_id);
                    };
                    matchMessage.$el.querySelector('.dcrRead2').onclick = () => {
                      item.autoClose = false;
                      this.handleRead(item.notice_id, item.task_type);
                      matchMessage.close();
                    };
                  }, 0);
                }

                if (
                  this.uin === item.create_by &&
                  (item.dcr_operation === 2 || item.dcr_operation === 3)
                ) {
                  setTimeout(() => {
                    const matchMessage = this.$notify({
                      duration: 3000,
                      showClose: false,
                      dangerouslyUseHTMLString: true,
                      message: `<div style="display:flex;">
                    <img src=${info} alt="" style="height: 18px;width: 18px;margin-top: 3px;margin-right: 8px;"><div style="font-size:14px;line-height:24px;font-weight:500;color:#1A2734">
                   您已于${this.formatTime(
                     item.create_time,
                   )}创建的DCR <span style="color:#0069FA;font-size:14px;line-height:22px;cursor: pointer;" class="btn3" >（${
                        item.dcr_code
                      }）</span>,已处理完成，处理结果为${this.formatMessage(item.dcr_operation)}。
                </div>
                    </div>
                <div style="display:flex;justify-content: space-between;">
                  <div style="margin-top:16px;color:#0069FA;font-size:14px;line-height:22px;cursor: pointer;">
                    <span class="dcrRead3">标记为已读</span>
                  </div>
                </div>
                `,
                      onClose: () => {
                        if (!item.autoClose) {
                          return;
                        }
                        this.handleWindow(item.notice_id);
                      },
                    });
                    matchMessage.$el.querySelector('.btn3').onclick = () => {
                      this.handleGoDcr(item.dcr_id);
                    };
                    matchMessage.$el.querySelector('.dcrRead3').onclick = () => {
                      item.autoClose = false;
                      this.handleRead(item.notice_id, item.task_type);
                      matchMessage.close();
                    };
                  }, 0);
                }

                if (
                  this.uin !== item.create_by &&
                  this.role === 1 &&
                  (item.dcr_operation === 2 || item.dcr_operation === 3)
                ) {
                  setTimeout(() => {
                    const matchMessage = this.$notify({
                      duration: 3000,
                      showClose: false,
                      dangerouslyUseHTMLString: true,
                      message: `<div style="display:flex;">
                    <img src=${info} alt="" style="height: 18px;width: 18px;margin-top: 3px;margin-right: 8px;"><div style="font-size:14px;line-height:24px;font-weight:500;color:#1A2734">
                    您已处理完成，${this.formatTime(item.create_time)}${
                        item.dcr_create_user
                      }创建的DCR <span style="color:#0069FA;font-size:14px;line-height:22px;cursor: pointer;"  class="btn4">（${
                        item.dcr_code
                      }）</span>,处理结果为${this.formatMessage(item.dcr_operation)}。
                </div>
                    </div>
                <div style="display:flex;justify-content: space-between;">
                  <div style="margin-top:16px;color:#0069FA;font-size:14px;line-height:22px;cursor: pointer;">
                    <span class="dcrRead4">标记为已读</span>
                  </div>
                </div>
                `,
                      onClose: () => {
                        if (!item.autoClose) {
                          return;
                        }
                        this.handleWindow(item.notice_id);
                      },
                    });
                    matchMessage.$el.querySelector('.btn4').onclick = () => {
                      this.handleGoDcr(item.dcr_id);
                    };
                    matchMessage.$el.querySelector('.dcrRead4').onclick = () => {
                      item.autoClose = false;
                      this.handleRead(item.notice_id, item.task_type);
                      matchMessage.close();
                    };
                  }, 0);
                }

                if (this.uin !== item.create_by && this.role === 1 && item.dcr_operation === 4) {
                  setTimeout(() => {
                    const matchMessage = this.$notify({
                      duration: 3000,
                      showClose: false,
                      dangerouslyUseHTMLString: true,
                      message: `<div style="display:flex;">
                    <img src=${info} alt="" style="height: 18px;width: 18px;margin-top: 3px;margin-right: 8px;"><div style="font-size:14px;line-height:24px;font-weight:500;color:#1A2734">
                    您已于${this.formatTime(
                      item.create_time,
                    )}成功提交DCR <span style="color:#0069FA;font-size:14px;line-height:22px;cursor: pointer;"  class="btn5">（${
                        item.dcr_code
                      }）</span>数据验证，正在等待平台管理员处理。
                </div>
                    </div>
                <div style="display:flex;justify-content: space-between;">
                  <div style="margin-top:16px;color:#0069FA;font-size:14px;line-height:22px;cursor: pointer;">
                    <span class="dcrRead5">标记为已读</span>
                  </div>
                </div>
                `,
                      onClose: () => {
                        if (!item.autoClose) {
                          return;
                        }
                        this.handleWindow(item.notice_id);
                      },
                    });
                    matchMessage.$el.querySelector('.btn5').onclick = () => {
                      this.handleGoDcr(item.dcr_id);
                    };
                    matchMessage.$el.querySelector('.dcrRead5').onclick = () => {
                      item.autoClose = false;
                      this.handleRead(item.notice_id, item.task_type);
                      matchMessage.close();
                    };
                  }, 0);
                }

                if (this.uin === item.create_by && item.dcr_operation === 4 && this.role === 1) {
                  setTimeout(() => {
                    const matchMessage = this.$notify({
                      duration: 3000,
                      showClose: false,
                      dangerouslyUseHTMLString: true,
                      message: `<div style="display:flex;">
                    <img src=${info} alt="" style="height: 18px;width: 18px;margin-top: 3px;margin-right: 8px;"><div style="font-size:14px;line-height:24px;font-weight:500;color:#1A2734">
                    ${item.dcr_create_user_company}-${item.dcr_create_user}已于${this.formatTime(
                        item.create_time,
                      )}成功提交DCR<span style="color:#0069FA;font-size:14px;line-height:22px;cursor: pointer;" class="btn6">（${
                        item.dcr_code
                      }）</span>数据验证，正在等待平台管理员处理。
                </div>
                    </div>
                <div style="display:flex;justify-content: space-between;">
                  <div style="margin-top:16px;color:#0069FA;font-size:14px;line-height:22px;cursor: pointer;">
                    <span class="dcrRead6">标记为已读</span>
                  </div>
                </div>
                `,
                      onClose: () => {
                        if (!item.autoClose) {
                          return;
                        }
                        this.handleWindow(item.notice_id);
                      },
                    });
                    matchMessage.$el.querySelector('.btn6').onclick = () => {
                      this.handleGoDcr(item.dcr_id);
                    };
                    matchMessage.$el.querySelector('.dcrRead6').onclick = () => {
                      item.autoClose = false;
                      this.handleRead(item.notice_id, item.task_type);
                      matchMessage.close();
                    };
                  }, 0);
                }

                if (
                  this.uin === item.create_by &&
                  (item.dcr_operation === 5 || item.dcr_operation === 6) &&
                  this.role === 1
                ) {
                  setTimeout(() => {
                    const matchMessage = this.$notify({
                      duration: 3000,
                      showClose: false,
                      dangerouslyUseHTMLString: true,
                      message: `<div style="display:flex;">
                    <img src=${info} alt="" style="height: 18px;width: 18px;margin-top: 3px;margin-right: 8px;"><div style="font-size:14px;line-height:24px;font-weight:500;color:#1A2734">
                   您已于${this.formatTime(
                     item.create_time,
                   )}提交验证的DCR <span style="color:#0069FA;font-size:14px;line-height:22px;cursor: pointer;" class="btn7">（${
                        item.dcr_code
                      }）</span>已处理完成，处理结果为${this.formatMessage(item.dcr_operation)}。
                </div>
                    </div>
                <div style="display:flex;justify-content: space-between;">
                  <div style="margin-top:16px;color:#0069FA;font-size:14px;line-height:22px;cursor: pointer;">
                    <span class="dcrRead7">标记为已读</span>
                  </div>
                </div>
                `,
                      onClose: () => {
                        if (!item.autoClose) {
                          return;
                        }
                        this.handleWindow(item.notice_id);
                      },
                    });
                    matchMessage.$el.querySelector('.btn7').onclick = () => {
                      this.handleGoDcr(item.dcr_id);
                    };
                    matchMessage.$el.querySelector('.dcrRead7').onclick = () => {
                      item.autoClose = false;
                      this.handleRead(item.notice_id, item.task_type);
                      matchMessage.close();
                    };
                  }, 0);
                }

                if (
                  this.uin !== item.create_by &&
                  this.role === 1 &&
                  (item.dcr_operation === 5 || item.dcr_operation === 6)
                ) {
                  setTimeout(() => {
                    const matchMessage = this.$notify({
                      duration: 3000,
                      showClose: false,
                      dangerouslyUseHTMLString: true,
                      message: `<div style="display:flex;">
                    <img src=${info} alt="" style="height: 18px;width: 18px;margin-top: 3px;margin-right: 8px;"><div style="font-size:14px;line-height:24px;font-weight:500;color:#1A2734">
                  您已处理完成，${this.formatTime(item.create_time)}${
                        item.dcr_create_user_company
                      }-${
                        item.dcr_create_user
                      }提交的DCR <span style="color:#0069FA;font-size:14px;line-height:22px;cursor: pointer;"  class="btn8">（${
                        item.dcr_code
                      }）</span>数据验证，处理结果为${this.formatMessage(item.dcr_operation)}。
                </div>
                    </div>
                <div style="display:flex;justify-content: space-between;">
                  <div style="margin-top:16px;color:#0069FA;font-size:14px;line-height:22px;cursor: pointer;">
                    <span class="dcrRead8">标记为已读</span>
                  </div>
                </div>
                `,
                      onClose: () => {
                        if (!item.autoClose) {
                          return;
                        }
                        this.handleWindow(item.notice_id);
                      },
                    });
                    matchMessage.$el.querySelector('.btn8').onclick = () => {
                      this.handleGoDcr(item.dcr_id);
                    };
                    matchMessage.$el.querySelector('.dcrRead8').onclick = () => {
                      item.autoClose = false;
                      this.handleRead(item.notice_id, item.task_type);
                      matchMessage.close();
                    };
                  }, 0);
                }
              } else {
                setTimeout(() => {
                  const errMessage = this.$notify({
                    duration: 3000,
                    showClose: false,
                    dangerouslyUseHTMLString: true,
                    message: `<div style="display:flex;align-items: center;">
                    <img src=${info} alt=""><div style="font-size:16px;line-height:24px;font-weight:500;color:#1A2734">
                【${item.task_type}】 任务状态通知：${
                      item.task_status === 3 ? '已完成' : '执行失败'
                    }
                </div>
                </div>
                <div style="display:flex;justify-content: space-between;">
                    <div style="margin-top:16px;font-size:14px;line-height:18px;">
                      <span style="color#4D5762;">失败原因：${item.fail_reson}</span>
                    </div>
                    <div style="margin-top:16px;color:#0069FA;font-size:14px;line-height:22px;cursor: pointer;">
                      <span class="read">标记为已读</span>
                    </div>
                </div>
                `,
                    onClose: () => {
                      if (!item.autoClose) {
                        return;
                      }
                      this.handleWindow(item.notice_id);
                    },
                  });
                  errMessage.$el.querySelector('.read').onclick = () => {
                    item.autoClose = false;
                    this.handleRead(item.notice_id, item.task_type);
                    errMessage.close();
                  };
                }, 0);
              }
            });
          }
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    },
    formatMessage(val) {
      if (val === 2 || val === 5) {
        return '接受';
      }
      if (val === 3 || val === 6) {
        return '拒绝';
      }
      return '';
    },
    formatTime(val) {
      if (val && !val.startsWith('0')) {
        return dateFormat(new Date(val), 'YYYY年MM月dd日');
      }
      return '';
    },
    handleGoDcr(id) {
      const href = this.$router.resolve({
        path: '/dashboard/dcrRecord-detail',
        query: {
          id,
        },
      });
      window.open(href.href, '_blank');
    },
    handleGoDetail(id) {
      const href = this.$router.resolve({
        path: '/dashboard/tag-manage-detail',
        query: {
          id,
        },
      });
      window.open(href.href, '_blank');
    },
    // 获取全部未读消息数量
    getUnreadMessage() {
      const req = {
        task_type: '',
        task_status: 0,
        notice_status: 2,
        time_interval: [],
        index: -1,
        // page_size: '',
      };
      GetTaskNotices(req)
        .then(res => {
          // console.log('全部未读', res);
          this.messageTotal = res.total;
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    },
    // 修改弹窗状态
    handleWindow(id) {
      const arr = [];
      if (id !== '') {
        arr.push(id);
      }
      const req = {
        notice_ids: arr,
      };
      PopTaskNotices(req)
        .then(() => {
          // console.log('修改了', res);
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    },
    // 获取数据匹配的单条数据
    handleMatch(id) {
      const req = {
        task_id: id,
      };
      GetDoctorDataMatchTaskInfo(req)
        .then(res => {
          console.log('数据匹配', res);
          const arr = [];
          arr.push(res.info);
          this.doctorMatch = arr.map(item => {
            if (item.status === 1) {
              return { ...item, status: '排队中' };
            }
            if (item.status === 2) {
              return { ...item, status: '执行中' };
            }
            if (item.status === 3) {
              return { ...item, status: '已完成' };
            }
            if (item.status === 4) {
              return { ...item, status: '任务失败' };
            }
          });
          const href = this.$router.resolve({
            path: '/dashboard/doctor-match-result',
            query: {
              create_time: this.doctorMatch[0].create_time,
              create_user: this.doctorMatch[0].create_user,
              end_time: this.doctorMatch[0].end_time,
              remark: this.doctorMatch[0].remark,
              status: this.doctorMatch[0].status,
              task_id: this.doctorMatch[0].task_id,
              file_name: this.doctorMatch[0].file_name,
              file_url: this.doctorMatch[0].file_url,
            },
          });
          window.open(href.href, '_blank');
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    },
    // 跳转消息中心页面
    handleMessage() {
      this.g_tourl('/dashboard/message');
    },
    // 消息已读
    handleRead(id, type) {
      const arr = [];
      if (id) {
        arr.push(id);
      }
      const req = {
        notice_ids: arr,
        task_type: type,
        task_status: 3,
        time_interval: [],
      };
      ReadTaskNotices(req)
        .then(() => {
          this.$message.success('消息成功已读');
          // this.getMessage();
          this.getUnreadMessage();
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    },
    init() {
      if (this.$route.name === 'search') {
        // 进入搜索页时判断搜索类型是否合法 是否存在于列表内
        let validType = false;
        this.searchTypeList.forEach(item => {
          if (item.value === this.$route.query.type) {
            validType = true;
          }
        });
        if (!validType) {
          this.$message.error('搜索类型无效');
          this.$router.push('home');
        } else {
          // 进入搜索页面一定带着搜索类型与关键字
          this.searchType = this.$route.query.type;
          this.searchText = this.$route.query.text;
        }
      } else {
        this.searchType = 'all';
        this.searchText = '';
      }
    },
    toSearch() {
      // 跳转到搜索页前进行校验
      if (this.searchText.length === 0) {
        this.$message.error('搜索内容不能为空');
      } else if (this.searchText.length < 2) {
        this.$message.error('至少输入2个中文字符长度');
      }
      //  else if (this.searchText.length > 10) {
      //   this.$message.error('最多只能输入10个字符');
      // }
      else if (SPECIAL_CHAR.test(this.searchText)) {
        this.$message.error('不能输入特殊字符');
      } else {
        this.$router.push({
          path: `/dashboard/search?type=${this.searchType}&text=${this.searchText}`,
        });
      }
    },
    // 跳转高级搜索页面
    toAdvanceSearch() {
      this.$router.push({
        path: '/dashboard/advance-search',
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import './top-bar.scss';
</style>
