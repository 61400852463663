import request from '@/libs/request';

// 创建DCR
export const CreateDcr = data => request('MdmAdmin/DCRService/CreateDcr', data);
// 审批DCR
export const ExamineDcr = data => request('MdmAdmin/DCRService/ExamineDcr', data);
// 查询DCR统计数据
export const QueryStatisticalOfDcr = data =>
  request('MdmAdmin/DCRService/QueryStatisticalOfDcr', data);
// 查询DCR列表
export const QueryDcrList = data => request('MdmAdmin/DCRService/QueryDcrList', data);
// 查询DCR详情
export const QueryDcrDetail = data => request('MdmAdmin/DCRService/QueryDcrDetail', data);
// 查询DCR明细
export const QueryDcrDetails = data => request('MdmAdmin/DCRService/QueryDcrDetails', data);
// 编辑DCR明细
export const ModifyDcrDetails = data => request('MdmAdmin/DCRService/ModifyDcrDetails', data);
// 协助验证DCR
export const AssistVerify = data => request('MdmAdmin/DCRService/AssistVerify', data);
// 判断是否为高级版
export const IsVip = data => request('MdmAdmin/User/IsVip', data);
