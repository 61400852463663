<template>
  <div class="dashboard-container">
    <div class="dashboard-content-container">
      <side-bar class="dashboard-sidebar" />
      <div class="dashboard-content">
        <top-bar class="dashboard-topbar" />
        <!-- <div class="dashboard-breadcrumb">
          <template v-for="(item, index) in breadcrumb">
            <div class="dashboard-breadcrumb-link" :key="index" @click="goto(item, index)">
              {{ item.name }}
            </div>
            <i
              :key="index + 'i'"
              v-if="index !== breadcrumb.length - 1"
              class="el-icon-arrow-right"
            ></i>
          </template>
        </div> -->
        <!-- <router-view /> -->
        <keep-alive :include="cache">
          <router-view />
        </keep-alive>
        <!-- <div class="dashboard-copyright">
          Copyright©️2021 Tencent Inc. All Rights Reserved 腾讯公司 版权所有
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import topBar from '@/components/top-bar';
import sideBar from '@/components/side-bar';
import store from '@/store';
export default {
  name: 'Dashboard',
  components: {
    topBar,
    sideBar,
  },
  data() {
    return {
      cache: ['DoctorImage', 'ReferenceDataModel'],
    };
  },
  computed: {
    breadcrumb() {
      return store.state.user.breadcrumb;
    },
  },
  mounted() {},
  methods: {
    goto(item, index) {
      // 跳转到当前页面的操作无意义也会引发报错 屏蔽掉
      if (index === this.breadcrumb.length - 1) {
        return;
      }
      const { path, params = {} } = item;

      const { breadcrumb } = store.state.user;
      while (breadcrumb.length > index) breadcrumb.pop();
      store.commit('user/setBreadcrumb', breadcrumb);

      this.$router.push({ path, params });
    },
  },
};
</script>
<style lang="scss" scoped>
@import './dashboard.scss';
</style>
