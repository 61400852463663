export default {
  list: [
    // index为undifiend的不激活左侧菜单高亮
    { index: undefined, path: '/login' },
    { index: undefined, path: '/dashboard/search' },
    { path: '/dashboard/home' },
    // 同样的组件 根据type不同决定显示的不同
    { path: '/dashboard/data-list', type: 'hospital' },
    { path: '/dashboard/data-list', type: 'hospital-ji' },
    { path: '/dashboard/data-list', type: 'hospital-pro' },
    { path: '/dashboard/data-list', type: 'hospital-other' },
    { path: '/dashboard/data-list', type: 'dealer' },
    { path: '/dashboard/data-list', type: 'shop' },
    { path: '/dashboard/data-list', type: 'manufacturer' },
    { path: '/dashboard/data-list', type: 'medical-machine' },
    { path: '/dashboard/data-list', type: 'other' },
    { path: '/dashboard/data-list', type: 'doctor' },
    { path: '/dashboard/data-list', type: 'mid-org' },
    { path: '/dashboard/data-list', type: 'mid-doc' },
    { path: '/dashboard/import-export' },
    { path: '/dashboard/source-search' },
    { path: '/dashboard/doctor-match' },
    { path: '/dashboard/org-map' },
    { path: '/dashboard/trade-search' },
    { path: '/dashboard/user-manage' },
    { path: '/dashboard/user-create' },
    { path: '/dashboard/action-log' },
    { path: '/dashboard/login-log' },
    { path: '/dashboard/tag-management' },
    { path: '/dashboard/dcr-record' },
    { path: '/dashboard/doctor-image' },
    { path: '/dashboard/jurisdiction-management' },
    { path: '/dashboard/model-config' },
    { path: '/dashboard/reference-data-model' },
    { path: '/dashboard/drug-list', type: 'spu' },
    { path: '/dashboard/drug-list', type: 'sku' },
  ],
};
