<template>
  <div class="sidebar" :style="{ width: isCollapse ? '70px' : '208px' }">
    <el-menu
      ref="menu"
      class="sidebar-menu"
      active-text-color="#ffd04b"
      :default-active="defaultActive"
      :default-openeds="activeMenu"
      :collapse="isCollapse"
      :collapse-transition="false"
    >
      <template>
        <div class="topbar-icon">
          <img class="logo" src="./icon/logo.png" alt="" />
          <!-- <img class="txt" src="./icon/logoText.svg" alt="" /> -->
        </div>

        <div
          v-if="!isCollapse"
          :class="`sidebar-dashboard ${defaultActive == 0 ? 'is-active' : ''}`"
          @click="goto('/dashboard/home')"
        >
          <img src="./icon/overview-active.svg" alt="" v-if="defaultActive == 0" />
          <img src="./icon/overview.svg" alt="" v-if="defaultActive != 0" />
          数据概览
        </div>
        <div
          v-else
          :class="`sidebar-dashboard ${defaultActive == 0 ? 'is-active' : ''}`"
          @click="goto('/dashboard/home')"
        >
          D
        </div>
      </template>
      <template v-for="(block, blockIndex) in menuList">
        <div v-if="!isCollapse" :key="`${blockIndex}`" class="sidebar-menu-title">
          {{ block.menu_name }}
        </div>
        <template v-for="(submenu1, submenu1Index) in block.children">
          <el-submenu
            v-if="submenu1.children.length !== 0"
            :key="`${blockIndex}-${submenu1Index}`"
            :index="`${blockIndex}-${submenu1Index}`"
          >
            <template slot="title">
              <img class="sidebar-icon" :src="iconList[submenu1.menu_icon]" />
              <span>{{ submenu1.menu_name }}</span>
            </template>
            <el-menu-item
              v-for="(submenu2, submenu2Index) in submenu1.children"
              :key="`${blockIndex}-${submenu1Index}-${submenu2Index}`"
              :index="`${blockIndex}-${submenu1Index}-${submenu2Index}`"
              @click="goto(submenu2.menu_path)"
            >
              {{ submenu2.menu_name }}
            </el-menu-item>
          </el-submenu>
          <el-menu-item
            class="menu-item"
            v-else-if="submenu1.hidden !== 1"
            :key="`${blockIndex}-${submenu1Index}`"
            :index="`${blockIndex}-${submenu1Index}`"
            @click="goto(submenu1.menu_path)"
          >
            <img class="sidebar-icon" :src="iconList[submenu1.menu_icon]" />
            <span slot="title">{{ submenu1.menu_name }}</span>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
    <div v-if="false" class="sidebar-switch">
      <i class="el-icon-location" @click="isCollapse = !isCollapse"></i>
    </div>
  </div>
</template>
<script>
import transportTable from './transport';
import { MdmAdminLogin } from '@/api/user';
import store from '@/store';
import iconList from './icon';
export default {
  name: 'Sidebar',
  components: {},
  data() {
    return {
      menuList: [],
      // 控制折叠开关
      isCollapse: false,
      // 激活的菜单条目
      defaultActive: '',
      // 展开的子菜单
      activeMenu: [],
      // 图标路径列表
      iconList,
      // 扁平化子菜单列表
      flatList: [],
    };
  },
  computed: {
    role() {
      const newRole = JSON.parse(localStorage.getItem('role'));
      // 页面刷新时
      if (newRole) {
        this.$store.commit('user/updateRole', newRole);
      } else {
        return this.$store.state.user.role;
      }

      return newRole;
    },
  },
  watch: {
    $route(to, from) {
      console.log(to);
      if (from.path === '/login') {
        this.init();
      }
      if (to.path === '/dashboard/action-log') {
        console.log(this.defaultActive);
      }
      this.layoutMenu();
    },
    role(to, from) {
      if (to !== from) {
        this.init();
      }
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      // 登陆进系统时固定触发一次用户是否在系统内的检测
      MdmAdminLogin()
        .then(res => {
          // 存在的话就登记role值用于判定权限
          store.commit('user/setRole', res.role);
          store.commit('user/setElement', res.element_list);

          // 将菜单附加上index属性
          this.getIndexList(res.menu_list, '');
          this.flatList = this.getFlatList(res.menu_list);
          this.menuList = res.menu_list;

          this.layoutMenu();
        })
        .catch(e => {
          console.log(e);
          // 不存在的话就返回登陆页
          if (e.payload.menu_list === undefined || e.payload.menu_list.length === 0) {
            this.$router.push('/login');
          }
        });

      // 收起所有的菜单
      this.activeMenu = [];
    },
    getIndexList(list, prefix = '') {
      list.forEach((item, index) => {
        const nextPrefix = `${prefix}${prefix ? '-' : ''}${index}`;
        item.index = nextPrefix;
        if (item.children.length) this.getIndexList(item.children, nextPrefix);
      });
    },
    getFlatList(list) {
      const result = [];
      list.forEach(item => {
        if (item.children.length) result.push(...this.getFlatList(item.children));
        else result.push(item);
      });
      return result;
    },
    // 获取index 如果index内有-说明为分级菜单 需要手动展开它
    layoutMenu() {
      if (this.$route.fullPath === '/dashboard/home') {
        this.defaultActive = '0';
        return;
      }
      const result = this.flatList.filter(item => {
        if (
          item.menu_path === '/dashboard/doctor-image' &&
          this.$route.path === '/dashboard/doctor-image-detail'
        ) {
          return item.menu_path;
        }
        if (
          item.menu_path === '/dashboard/reference-data-model' &&
          this.$route.path === '/dashboard/reference-data-model-detail'
        ) {
          return item.menu_path;
        }
        return item.menu_path === this.$route.fullPath || item.menu_path === this.$route.path;
      });
      if (result.length > 0) {
        this.defaultActive = result[0].index;
        if (this.defaultActive !== undefined && this.defaultActive.indexOf('-') !== -1) {
          this.activeMenu.push(this.defaultActive.split('-')[0]);
        }
      } else {
        this.defaultActive = '-1';
      }
    },
    // 根据index在路由表内取出第一个符合条件的路径跳转 如果有type就附加上type
    goto(path) {
      store.commit('user/setBreadcrumb', []); // 路由切换，页面面包屑清除
      this.$router.push({ path }).catch(() => {});
    },
    // 从路由索引对应表中获得信息对象 只取第一个符合条件的对象
    getTransport(key, value, type) {
      let result = undefined;
      transportTable.list.forEach(obj => {
        if (obj[key] === value && obj.type === type && result === undefined) result = obj;
      });
      return result;
    },
  },
};
</script>
<style lang="scss" scoped>
@import './side-bar.scss';
</style>
