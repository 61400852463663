import hospital from './hospital.svg';
import doctor from './doctor.svg';
import importExport from './import-export.svg';
import sourceSearch from './source-search.svg';
import systemLog from './system-log.svg';
import userManage from './user-manage.svg';
import doctorMatch from './doctorMatch.svg';
import orgMap from './orgMap.svg';
import tradeSearch from './trade-search.svg';
import tagManagement from './tag-manage.svg';
import DCRRecord from './dcr-record.svg';
import doctorImage from './doctor-image.svg';
import jurisdictionManagement from './jurisdiction-management.svg';
import configCenter from './config-center.svg';
import drug from './drug.svg';

export default {
  hospital,
  doctor,
  importExport,
  sourceSearch,
  systemLog,
  userManage,
  doctorMatch,
  orgMap,
  tradeSearch,
  tagManagement,
  DCRRecord,
  doctorImage,
  jurisdictionManagement,
  configCenter,
  drug,
};
